<template>
  <div class="footer">
            <p><sup>+</sup>On the basis of your profile</p>
            <p>Premium displayed for 18 years old, 20 years policy term</p>
            <p>Prices offered by the insurer are as per the IRDAI approved insurance plans</p>
            <p>All savings and online discounts are provided by insurers as per IRDAI approved insurance plans</p>
            <p>Standard Terms and Conditions Apply.</p>
            <p>Tax Benefits are subject to changes in tax laws.</p>                           
            <p>Policybazaar Insurance Brokers Private Limited (formerly known as Policybazaar Insurance Web Aggregator Private Limited)</p>
<p><sup>*</sup>We will respond in the first instance within 30 minutes of the customers contacting us. 30-minute claim support service is for the purpose of giving reasonable assistance to the policyholder in pursuance of the claim. Settlement of claim (including cashless claim) is the responsibility of the insurer as per policy terms and conditions. The 30-minute claim support is subject to our operations not being impacted by a system failure or force majeure event or for reasons beyond our control. For further details, 24x7 Claims Support Helpline can be reached out at 1800-258-5881</p>
<p>Policybazaar Insurance Brokers Private Limited | CIN: U74999HR2014PTC053454 | Registered Office - Plot No.119, Sector - 44, Gurgaon, Haryana – 122001 | Registration No. 742, Valid till 09/06/2027, License category- Composite Broker | Visitors are hereby informed that their information submitted on the website may be shared with insurers. Product information is authentic and solely based on the information received from the insurers.<br/></p>
<p>© Copyright 2008-2025 policybazaar.com. All Rights Reserved.</p>
</div>

</template>

<script lang="ts">
import { defineComponent, Prop } from 'vue';

export default defineComponent({
    name:"FooterComponent",
   props: {
    msg: {
      type: String,
      required: false,
    },
  },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
    margin-top: 120px;
  }
  .footer {
  width: 1080px;
  float: none;
  text-align: center;
  font-size: 10px;
  margin: 20px auto 0;
display: table;
padding-top: 25px;
  padding-bottom:50px;
}
.footer p {
  margin: 0;
  color: var(--footertext);
  line-height: 18px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.2px;
  padding: 0 15px;
}
.footer p a {
  color: #157bfb;
  text-decoration: none;
  font-weight: 400;

}

@media (min-width: 320px) and (max-width: 1024px) {
  .footer {margin-top:100px; width:100%; }
  .footer p { font-size:9px;}
}

</style>
